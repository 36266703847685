import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

function App() {
  const [apiResult, setApiResult] = useState("");
  const [longUrl, setLongUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setBtnDisabled(true);
    fetch("/create", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "longUrl": longUrl, "shortUrl": shortUrl })
    })
      .then(response => response.text())
      .then(text => {
        setApiResult(text);
        setLongUrl("");
        setShortUrl("");
        setBtnDisabled(false);
      })
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Create an alias for a URL</p>
        <form onSubmit={handleSubmit}>
          <label>URL:<input type="text" name="longUrl" onChange={(e) => setLongUrl(e.target.value)} /></label><br />
          <label>Alias:<input type="text" name="shortUrl" onChange={(e) => setShortUrl(e.target.value)} /></label><br />
          <button disabled={btnDisabled}>Submit</button>
        </form>
        {apiResult && <p>Result from the API: {apiResult}</p>}
      </header>
    </div>
  );
}

export default App;
